<template>
    <f7-sheet
        class="mobile-app-sheet"
        backdrop
        swipe-to-close
        close-by-backdrop-click
        close-by-outside-click
        @sheet:closed="closeModal()"
    >
        <div>
            <div class="mobile-app-sheet__img"></div>
            <div class="mobile-app-sheet__title">Заказывайте через удобное приложение МИР СИТИ</div>
            <!-- <div class="mobile-app-sheet__text">
                Установите приложение “Мир-Сити” и доступ к любимым ресторанам и
                услугам всегда будет под рукой.
            </div> -->
        </div>
        <div>
            <button
                :class="`custom-button mobile-app-sheet__button-submit`"
                @click.stop="openMobileApp"
            >Открыть приложение</button>
            <button :class="`custom-button`" @click.stop="$f7.sheet.close()">Не сейчас</button>
        </div>
    </f7-sheet>
</template>

<script>
    import { Device } from 'framework7';
    export default {
        name: 'MobileAppSheet',
        data: () => ({
            shown: false,
        }),
        mounted() {
            this.$root.$on('mobileAppSheetShow', async () => {
                if(!Device.cordova) {
                    await new Promise(resolve => setTimeout(resolve, 60 * 1000));
                    this.$f7.sheet.open('.mobile-app-sheet');
                }
            });
            this.$root.$on('mobileAppSheetHide', () => {
                this.$f7.sheet.close();
            });
        },
        methods: {
            openMobileApp() {
                window.open(
                    process.env.VUE_APP_APP_LINK_APP_METRICA,
                    '_blank'
                );
            },
            closeModal() {
                window.dispatchEvent(new Event('resize'));
            }
        },
    };
</script>

<style lang="scss">
.mobile-app-sheet {
    height: 340px;
    .sheet-modal-inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
    }

    &__img {
        height: 50px;
        background: url('../../static/logo-icon.svg') center center no-repeat;
        background-size: contain;
        margin-top: 10px;
    }
    &__title {
        padding: 20px 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        color: #000000;
        text-align: center;
    }
    &__text {
        padding-right: 25px;
        margin: 10px 0 24px;
        font-size: 14px;
        line-height: 17px;
        color: #232321;
    }
    &__buttons {
        display: flex;
        justify-content: space-between;
        > button {
            width: 48%;
        }
    }
    &__button-submit {
        background: linear-gradient(
            86.28deg,
            #eee1fb 0%,
            #fde1e4 34.9%,
            #d8e8f9 67.19%,
            #d2f4f4 100%
        );
        color: #232321;
        margin-bottom: 12px;
    }
}
</style>
