import axios from '../../plugins/axios';
import { CACHE_TIME } from '@/js/utils/vars';

export const namespaced = true;

export const state = () => ({
    entities: [],
    entitiesCount: 0,
    entity: {},
    categories: [],
    products: [],
    recomendations: [],
    productsCount: 0,
    product: {},
    lastUpdate: 0
});

export const mutations = {
    CLEAR_ENTITIES(state) {
        state.entities = [];
        state.entitiesCount = 0;
    },
    CLEAR_ENTITY(state) {
        state.entity = {};
        state.categories = [];
    },
    CLEAR_CATEGORIES(state) {
        state.categories = [];
    },
    CLEAR_PRODUCTS(state) {
        state.products = [];
        state.productsCount = 0;
    },
    CLEAR_PRODUCT(state) {
        state.product = {};
    },
    SET_ENTITIES(state, payload) {
        state.entities = payload;
    },
    SET_ENTITY(state, payload) {
        state.entity = payload;
    },
    SET_CATEGORIES(state, payload) {
        state.categories = payload;
    },
    SET_PRODUCTS(state, payload) {
        if(payload.page === 1) {
            state.products = [];
        }
        state.products = state.products.concat(payload.products);
        state.productsCount = payload.productsCount;
    },
    SET_RECOMENDATIONS(state, payload) {
        state.recomendations = payload;
    },
    SET_PRODUCT(state, payload) {
        state.product = payload;
    }
};

export const actions = {
    async fetch({ commit, state }, { forced = false }) {
        if(forced || !state.entities.length || ((new Date()).getTime() - state.lastUpdate > CACHE_TIME)) {
            state.lastUpdate = (new Date()).getTime();
            const { data } = await axios.get('/front/grocers');
            commit('SET_ENTITIES', data);
            return data;
        }
        return state.entities;
    },
    async fetchByTower({ commit, state }, { tower_id, forced = false }) {
        if(forced || !state.entities.length || ((new Date()).getTime() - state.lastUpdate > CACHE_TIME)) {
            state.lastUpdate = (new Date()).getTime();
            const { data } = await axios.get(`/front/grocers/sort-for-tower/${tower_id}`);
            commit('SET_ENTITIES', data);
            return data;
        }
        return state.entities;
    },
    async get({ commit }, { grocerId }) {
        const { data } = await axios.get(`/front/grocers/${ grocerId }`);
        commit('SET_CATEGORIES', data.categories);
        delete data.categories;
        commit('SET_ENTITY', data);
    },
    async getByCode({ commit }, { grocerCode }) {
        const { data } = await axios.get(`/front/grocers/code/${ grocerCode }`);
        commit('SET_CATEGORIES', data.categories);
        delete data.categories;
        commit('SET_ENTITY', data);
        return data;
    },
    async getProducts({ commit }, { grocerId, categoryId, page }) {
        if(page === 1) {
            commit('SET_PRODUCTS', { products: [], productsCount: 0, page: 1 });
        }
        const { data } = await axios.get(`/front/grocers/${ grocerId }/category/${ categoryId }?page=${ page }`);
        commit('SET_PRODUCTS', { products: data.results, productsCount: data.total, page });
    },
    async getRecomendations({ commit }, { grocerId }) {
        const { data } = await axios.get(`/front/grocers/${ grocerId }/popular`);
        commit('SET_RECOMENDATIONS', data);
    },
    async getProduct({ commit }, { grocerId, productId }) {
        commit('SET_PRODUCT', {});
        const { data } = await axios.get(`/front/grocers/${ grocerId }/product/${ productId }`);
        commit('SET_PRODUCT', data);
    }
};

export const getters = {
    categoriesFirstLevel: state => {
        return state.categories.filter(c => (c.parent_id === null || c.parent_id === 0 || c.id === 'popular'))
    },

    categoriesChildrenMap: state => {
        const res = { 0: [] };

        state.categories.forEach(category => {
            if (!category.parent_id) {
                res[0].push(category);
                return;
            }

            if (res[category.parent_id]) res[category.parent_id].push(category);
            else res[category.parent_id] = [category];
        });

        return res;
    },

    categoriesByIdMap: state => {
        const res = {};

        state.categories.forEach(category => {

            res[category.id ?? 0] = category;

            return res;
        });

        return res;
    },

    addedSubcategoriesCategoriesByIdMap: (state, { categoriesByIdMap, categoriesChildrenMap }) => {
        const res = {
            0: { id: 'root', subcategories: categoriesChildrenMap[0] }
        };

        Object.keys(categoriesByIdMap).forEach(id => {
            const newCategory = { ...categoriesByIdMap[id], subcategories: categoriesChildrenMap[id] ?? [] };

            if (!res[id]) res[id] = newCategory;
        });

        return res;
    }
};
