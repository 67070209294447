import axios from '../../plugins/axios-silent';
import indexedDB from '../../plugins/indexedDB';
import { state } from './style';

export default {
    namespaced: true,

    state: {
        banner: {},
        banners: [],
        banners_list: {},
        popup_banners_list: [],
        selection_carousel_banners_list: [],
        top_banners_profile: [],
        skeletonBanners: [
            {
                id: 1,
                skeleton: true,
            },
            {
                id: 2,
                skeleton: true,
            },
            {
                id: 3,
                skeleton: true,
            },
            {
                id: 4,
                skeleton: true,
            },
            {
                id: 5,
                skeleton: true,
            },
        ]
    },
    mutations: {
        SET_BANNER(state, payload) {
            state.banner = payload;
        },
        SET_BANNERS(state, payload) {
            state.banners = payload;
        },
        SET_BANNERS_LIST(state, payload) {
            state.banners_list[payload.routeName] = payload.result;
        },
        SET_POPUP_BANNERS_LIST(state, payload) {
            state.popup_banners_list = payload;
        },
        SET_SELECTION_CAROUSEL_BANNERS(state, payload) {
            state.selection_carousel_banners_list = payload;
        },
        CLEAR_BANNERS(state, payload) {
            state.banners = [];
        },
        SET_TOP_BANNERS_PROFILE(state, payload) {
            state.top_banners_profile = payload;
        }
    },
    actions: {
        clear({ state, commit } = {}) {
            state.banners = [];
        },
        clearList({ state, commit } = {}) {
        },
        async getById({ commit }, { id }) {
            const { data } = await axios.get(`/front/banners/id/${ id }`);
            commit('SET_BANNER', data);
        },
        // БАНЕР СВЕРХУ
        async getByRoute({ state, commit }, { routeName }) {
            const { data } = await axios.get(`/front/banners/list/${ routeName }`);
            commit('SET_BANNERS', data.filter(item => +item.place === 0));
        },
        // КАРУСЕЛЬ БАНЕР
        async getByRouteSelectionCarousel({ state, commit }, { routeName }) {
            const { data } = await axios.get(`/front/banners/selectionCarousel/${ routeName }`);
            commit('SET_SELECTION_CAROUSEL_BANNERS', data);
        },
        // ТОП БАНЕР В ПРОФИЛЕ
        async getTopBannerProfile({ state, commit }) {
            const { data } = await axios.get('/front/banners/profile');
            commit('SET_TOP_BANNERS_PROFILE', data);
        },
        async getByRoutePopupList({ state, commit }) {
            const { data } = await axios.get('/front/banners/popuplist');
            
            const result = [];
            const placesGroups = {};
            const localData = await indexedDB.get('mircity-popupBanersIndexes') || {};

            for (const banner of data.sort((a, b) => (a.place > b.place) ? -1 : 1)) {
                if (+banner.place !== 0) {
                    if (placesGroups[+banner.place]) {
                        placesGroups[+banner.place].push(banner);
                    } else {
                        placesGroups[+banner.place] = [ banner ];
                    }
                }
            }
            const nextLocalData = {};

            for (const groupKey in placesGroups) {
                const group = placesGroups[groupKey];
                const lastShownId = localData[groupKey] || null;
                const lastShowBannerIndex = lastShownId ? group.findIndex(b => b.id === lastShownId) : -1;
                let currentBanner;
                currentBanner = group[+lastShowBannerIndex + 1] || group[0];
                result.push(currentBanner);
                nextLocalData[groupKey] = currentBanner.id;
            }
            await indexedDB.set('mircity-popupBanersIndexes', nextLocalData);
            commit('SET_POPUP_BANNERS_LIST', result);
        },
        async getByRouteListPlaces({ state, commit }, { routeName }) {
            const { data } = await axios.get(`/front/banners/list/${routeName}`);

            const result = [];
            const placesGroups = {};
            const localData = await indexedDB.get('mircity-banersIndexes') || {};

            for (const banner of data.sort((a, b) => (a.place > b.place) ? -1 : 1)) {
                if (+banner.place !== 0) {
                    if (placesGroups[+banner.place]) {
                        placesGroups[+banner.place].push(banner);
                    } else {
                        placesGroups[+banner.place] = [ banner ];
                    }
                }
            }

            const nextLocalData = {};

            for (const groupKey in placesGroups) {
                const group = placesGroups[groupKey];
                const lastShownId = localData[groupKey] || null;
                const lastShowBannerIndex = lastShownId ? group.findIndex(b => b.id === lastShownId) : -1;
                let currentBanner;
                currentBanner = group[+lastShowBannerIndex + 1] || group[0];
                result.push(currentBanner);
                nextLocalData[groupKey] = currentBanner.id;
            }
            await indexedDB.set('mircity-banersIndexes', nextLocalData);

            commit('SET_BANNERS_LIST', { routeName, result });
        }
    }
}

