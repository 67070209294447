<template>
    <f7-popup
        class="survey-popup"
        tablet-fullscreen
    >
        <div class="survey-popup__content">
            <template v-if="activeQuestion.id">
                <div class="survey-popup__close" @click="close">
                </div>
                <div class="survey-popup__tabs" :style="`grid-template-columns: repeat(${survey.questions ? survey.questions.length : 0}, 1fr);`">
                    <template v-for="(item, index) in survey.questions">
                        <div 
                            :key="item.id" 
                            class="survey-popup__tabs__item" 
                            :class="{'survey-popup__tabs__item__active': index === activeQuestionIndex}"
                            @click="setActiveQuestion(item, index)"></div>
                    </template>
                </div>
            </template>
            <div v-if="activeQuestion.type === 'estimation'">
                <div class="survey-popup__image survey-popup__image__estimation">
                    <div :class="`survey-popup__star survey-popup__star-${estimation}`">
                    </div>
                </div>
                <div class="survey-popup__description">
                    Шаг {{ activeQuestionIndex + 1}} из {{ survey.questions ? survey.questions.length : 0 }}
                </div>
                <div class="survey-popup__title">
                    {{ activeQuestion.title }}
                </div>
                <f7-range 
                    class="survey-popup__estimation-range"
                    :min="1" 
                    :max="10" 
                    :step="1" 
                    :scale-steps="9" 
                    :value="estimation" 
                    color="orange"
                    :label="false" 
                    :scale="true" 
                    :limit-knob-position="false"
                    @range:change="setEstimation" />
                <div class="survey-popup__description" v-html="htmlFormatted(activeQuestion.description)">
                </div>
            </div>
            <div v-if="activeQuestion.type === 'comment'">
                <div class="survey-popup__image survey-popup__image__comment">
                    <div :class="`survey-popup__star survey-popup__star__big survey-popup__star-${estimation}`">
                    </div>
                </div>
                <div class="survey-popup__description">
                    Шаг {{ activeQuestionIndex + 1}} из {{ survey.questions ? survey.questions.length : 0 }}
                </div>
                <div class="survey-popup__title">
                    {{ activeQuestion.title }}
                </div>
                <textarea
                    type="text"
                    :value="comment"
                    @input="comment = $event.target.value"></textarea>
            </div>
            <div class="survey-popup__finish survey-popup__image__finish" v-if="activeQuestion.type === 'finish'">
                <div class="survey-popup__finish__title">
                    Ваше мнение очень важно для нас!
                </div>
                <div class="survey-popup__finish__description">
                    В благодарность за участие в опросе мы дарим промокод на услугу «Личный помощник»!
                </div>
                <button
                    :class="`custom-button survey-popup__button-submit`"
                    :style="buttonStyle"
                    :disabled="!finished"
                    @click.stop="toPromocodes"
                >Мои промокоды</button>
            </div>
            <div v-if="activeQuestion.id">
                <button
                    :class="`custom-button survey-popup__button-submit`"
                    :style="buttonStyle"
                    @click.stop="nextStep"
                >Далее</button>
            </div>
        </div>
    </f7-popup>
</template>

<script>
    import { f7 } from 'framework7-vue';
    import { Device } from 'framework7';
    import { sendAppMetricaEvent } from '@/js/utils/service-helpers';
    import store from '../../store';

    export default {
        name: 'Survey',
        data: () => ({
            shown: false,
            estimation: 10,
            comment: '',
            survey: {},
            activeQuestion: {
                type: ''
            },
            activeQuestionIndex: 0,
            finished: false
        }),
        computed: {
            imageSrc() {
                return this.survey.picture ? `${process.env.VUE_APP_IMAGES_URL}/${ this.survey.picture }` : '';
            },
            buttonStyle() {
                const style = [];
                if(this.survey.buttonColor) {
                    style.push(`color: ${this.survey.buttonColor};`);
                }
                if(this.survey.buttonBackground) {
                    style.push(`background: ${this.survey.buttonBackground};`);
                }
                return style.join(' ');
            }
        },
        mounted() {
            this.$root.$on('surveyShowPopup', async survey => {
                this.survey = {
                    ...this.survey,
                    ...survey
                }
                this.finished = false;
                this.activeQuestion = this.survey.questions[0];
                this.$f7.sheet.open('.survey-popup');
                // sendAppMetricaEvent('FORCE_UPDATE', {
                //     Action: 'view'
                // });
            });
        },
        methods: {
            close() {
                this.$f7.sheet.close('.survey-popup');
            },
            toPromocodes() {
                this.close();
                f7.views[0].router.navigate('/referal-program');
            },
            htmlFormatted(text) {
                return text ? text.replace(/\n/g, '<br>') : '';
            },
            setEstimation(value) {
                this.estimation = value;
            },
            setActiveQuestion(item, index) {
                this.activeQuestionIndex = index;
                this.activeQuestion = item;
            },
            nextStep() {
                if(this.survey.questions[this.activeQuestionIndex].type === 'estimation') {
                    this.survey.questions[this.activeQuestionIndex].estimation = this.estimation;
                }
                if(this.survey.questions[this.activeQuestionIndex].type === 'comment') {
                    this.survey.questions[this.activeQuestionIndex].comment = this.comment;
                }

                this.activeQuestionIndex += 1;
                if (this.survey.questions[this.activeQuestionIndex]) {
                    this.activeQuestion = this.survey.questions[this.activeQuestionIndex];
                    this.comment = this.survey.questions[this.activeQuestionIndex].comment;
                } else {
                    this.finish();
                }
            },
            async finish() {
                this.activeQuestion = { type: 'finish' };
                const results = [];
                for(const question of this.survey.questions) {
                    const result = {
                        survey_id: this.survey.id,
                        survey_question_id: question.id,
                        type: question.type,
                    };
                    switch(question.type) {
                    case 'estimation':
                        results.push({
                            ...result,
                            estimation: question.estimation,
                        });
                        break;
                    case 'comment':
                        results.push({
                            ...result,
                            comment: question.comment,
                        });
                        break;
                    }
                }
                await store.dispatch('surveys/saveResult', { results });
                this.finished = true;
            }
        },
    };
</script>

<style lang="scss">
.survey-popup {
    height: 100%;

    &__image {
        width: calc(100% + 64px);
        height: 260px;
        margin: 0 -32px 16px;
        background-position: center center;
        background-repeat: no-repeat;

        &__estimation {
            background-image: url('../../static/survey/bg-estimation.svg');
            background-size: contain;
        }
        &__comment {
            background-image: url('../../static/survey/bg-comment.svg');
            background-size: contain;
        }
        &__finish {
            background-image: url('../../static/survey/bg-finish.svg');
            background-size: cover;
        }
    }

    &__star {
        height: 260px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;

        &-1 {
            background-image: url('../../static/survey/star-1.svg');
        }
        &-2 {
            background-image: url('../../static/survey/star-2.svg');
        }
        &-3 {
            background-image: url('../../static/survey/star-3.svg');
        }
        &-4 {
            background-image: url('../../static/survey/star-4.svg');
        }
        &-5 {
            background-image: url('../../static/survey/star-5.svg');
        }
        &-6 {
            background-image: url('../../static/survey/star-6.svg');
        }
        &-7 {
            background-image: url('../../static/survey/star-7.svg');
        }
        &-8 {
            background-image: url('../../static/survey/star-8.svg');
        }
        &-9 {
            background-image: url('../../static/survey/star-9.svg');
        }
        &-10 {
            background-image: url('../../static/survey/star-10.svg');
        }

        &__big {
            img {
                width: 260px;
                height: 260px;
            }
        }
    }

    &__content {
        width: 100%;
        height: 100%;
        padding: 48px 32px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
    }

    &__tabs {
        display: grid;
        grid-gap: 12px;
        height: 60px;
        padding-top: 5px;

        &__item {
            width: 100%;
            height: 2px;
            background: #EAEAEA;
            opacity: 0.4;
            border-radius: 10px;

            &__active {
                background: #B8B8B8;
                opacity: 1;
            }
        }
    }

    &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 130%;
        color: #000000;
        margin-bottom: 32px;
    }
    
    &__description {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #B8B8B8;
        margin-bottom: 16px;
    }

    &__close {
        width: 25px;
        height: 25px;
        position: absolute;
        top: 75px;
        right: 20px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('../../static/survey/close.svg');
    }

    &__img {
        img {
            width: 100%;
        }
    }

    &__estimation-range {
        margin-bottom: 32px;

        .range-scale-step {
            top: -40px;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #B8B8B8;
        }
        .range-scale-step:before {
            content: none;
        }
        .range-knob {
            background: url('../../static/survey/knob.svg');
            background-size: contain;
        }
        .range-bar {
            height: 8px;
            background: #F3F3F3;
            margin-top: -4px;
            border-radius: 4px;
        }
        .range-bar-active {
            background: linear-gradient(90deg, #F8BD4B 2.72%, #E96E06 105.14%);
        }
    }

    textarea {
        width: 100%;
        height: 146px;
        background: #F5F5F5;
        border-radius: 6px;
        font-weight: 400;
        font-size: 15px;
        line-height: 140%;
        color: #232321;
        padding: 10px 12px;
    }

    &__button-submit {
        height: 47px;
        background: #FF7E8A;
        border-radius: 10px;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #FFFFFF;
    }

    &__finish {
        width: calc(100% + 64px);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 -32px;
        padding: 0 32px;

        &__title {
            font-weight: 600;
            font-size: 36px;
            line-height: 110%;
            text-align: center;
            color: #000000;
            margin-bottom: 56px;
        }

        &__description {
            font-weight: 400;
            font-size: 18px;
            line-height: 140%;
            text-align: center;
            color: #232321;
            margin-bottom: 56px;
        }
    }
}
</style>
