<template>
    <f7-sheet
        :opened="shown"
        class="repeat-order-sheet"
        style="height:auto; --f7-sheet-bg-color: #fff;"
        swipe-to-close
        @sheet:closed="closeModal()"
    >
        <template v-if="popup_banner.id">
            <div>
                <div class="repeat-order-sheet__header-banner">
                    <div class="repeat-order-sheet__swipe-line-banner"></div>
                    <div v-if="popup_banner.main_text" class="title_banner">{{ popup_banner.main_text }}</div>
                    <div v-if="popup_banner.title" class="repeat-order-sheet__subtitle_banner">{{ popup_banner.additional_text }}</div>
                </div>
                <div class="banner_picture">
                    <img :src="imageSrc(popup_banner.picture)" @click="onClick(popup_banner)" />
                </div>
                <button 
                    v-if="popup_banner.button_name"
                    class="button_banner"
                    @click="onClick(popup_banner)"
                >{{ popup_banner.button_name }}</button>
            </div>
        </template>

    </f7-sheet>
</template>

<script>
    import { f7 } from 'framework7-vue';
    import { mapState } from 'vuex';
    import store from '@/store';
    import indexedDB from '@/plugins/indexedDB';
    
    import { goToExternalLink, sendAppMetricaEvent } from '@/js/utils/service-helpers';

    export default {
        name: 'PopupBanner',

        components: {
        },

        data: () => ({
            shown: false,
            popup_banner: {},
            checkShow: false,
            checkActiveStories: false,
        }),

        computed: {
            ...mapState('banners', {
                banners: state => state.popup_banners_list
            }),
            ...mapState('orders', ['completedCount']),
            ...mapState('auth', {
                user: state => state.user
            }),
        },

        async created() {
            this.$root.$on('storiesShow', () => {
                this.checkActiveStories = true;
            });
            this.$root.$on('popupBannerSheetShow', async () => {
                // if (this.checkActiveStories) {
                //     return;
                // }
                // const checkStories = await this.hasActiveStories();
                // if (!checkStories) {
                //     return;
                // }

                // if (store.state.auth.user && store.state.auth.user.phone) {
                //     let orderForRepeat = await indexedDB.get('mircity-orderForRepeat');
                //     if(orderForRepeat) {
                //         await indexedDB.remove('mircity-orderForRepeat');
                //     }
                // }

                await store.dispatch('banners/getByRoutePopupList');
                for (const banner of this.banners) {
                    if (this.shown === false) {
                        this.popup_banner = banner;
                        sendAppMetricaEvent('POPUP_BANNER_OPENED', {
                            Action: 'view',
                            Page: 'main',
                            Banner: this.popup_banner?.code || this.popup_banner?.id
                        });
                        this.shown = true;
                    }
                }
                
                // await store.dispatch('banners/getByRoutePopupList');
                // let popupBanner = await indexedDB.get('mircity-popupBannerForMainPage') || [];
                // for (const banner of this.banners) {
                //     const checkBanner = popupBanner.find(a => a === banner.id);
                //     if (!checkBanner && this.shown === false) {
                //         this.popup_banner = banner;
                //         popupBanner.push(this.popup_banner.id)
                //         await indexedDB.set('mircity-popupBannerForMainPage', popupBanner );
                //         sendAppMetricaEvent('POPUP_BANNER_OPENED', {
                //             Action: 'view',
                //             Page: 'main',
                //             Banner: this.popup_banner?.code || this.popup_banner?.id
                //         });
                //         this.shown = true;
                //         this.checkShow = true;
                //     }
                // }
            });
        },
        methods: {
            imageSrc(picture) {
                return picture ? `${process.env.VUE_APP_IMAGES_URL}/${ picture }` : '';
            },

            async onClick(banner) {
                if (banner?.type) {
                    sendAppMetricaEvent('POPUP_BANNER_CLICK', {
                        Action: 'click',
                        Page: 'main',
                        Banner: this.popup_banner?.code || this.popup_banner?.id
                    });
                }


                switch(banner.type) {
                case 'link':
                    await f7.views[0].router.navigate(banner.link);
                    this.shown = false;
                    break
                case 'external_link':
                    goToExternalLink(banner.link);
                    this.shown = false;
                    break;
                case 'list':
                    await f7.views[0].router.navigate({
                        name: `${banner.route_name}-banner`,
                        params: {
                            id: banner.id
                        }
                    });
                    this.shown = false;
                    break;
                case 'products_list':
                    await f7.views[0].router.navigate({
                        name: banner.route_name + '-products-banner',
                        params: {
                            id: banner.id
                        }
                    });
                    this.shown = false;
                    break;
                default:
                    this.shown = false;
                    return false;
                }
            },
            async hasActiveStories() {
                let activeStories = await indexedDB.get('mircity-activeAllStories') || [];
                const storiesIndexes = await indexedDB.get('mircity-storiesIndexes') || {};
                const allStories = Object.values(storiesIndexes)
                if (allStories.length === 0) {
                    return true
                }
                activeStories.sort();
                allStories.sort();
                for(let i = 0; i < allStories.length; i++){
                    if (activeStories.indexOf(allStories[i]) == -1) {
                        return false;
                    }
                }
                return true;
            },
            closeModal() {
                window.dispatchEvent(new Event('resize'));
            }
        },
    };
</script>

<style lang="scss">
.repeat-order-sheet {
    &__header {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 50px;
    }

    &__header-banner {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        // padding-bottom: 16px;
    }

    &__swipe-line {
        width: 30px;
        height: 4px;
        background: #EAEAEA;
        border-radius: 40px;
        margin-bottom: 10px;
    }
    
    &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        margin-bottom: 10px;
    }
    
    &__subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #90949A;
        margin-bottom: 10px;
    }

    &__subtitle_banner {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #90949A;
        margin: 10px 0;
    }

    &__entity {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #90949A;

        > span {
            color: #000000;
        }
    }

    &__swipe-line-banner {
        width: 40px;
        height: 4px;
        background: #EAEAEA;
        border-radius: 40px;
        margin-bottom: 10px;
    }

    &__carousel {

        &__swiper {
            margin: 0 -20px;
            padding: 0 20px;
            margin-top: -36px;
            margin-bottom: 24px;
        }

        &__content {
            width: 100%;
            height: 100%;
            overflow: hidden;
            cursor: pointer;

            &-single {
                width: 100%;
                height: 100%;
            }
        }
        &__picture {
            width: 65px;
            height: 65px;
            border-radius: 14px;
            overflow: hidden;
            background-repeat: no-repeat !important;
            background-size: cover !important;
            background-position: center center;

            > div {
                width: 100%;
                height: 100%;
                background-repeat: no-repeat !important;
                background-size: cover !important;
                background-position: center center;
            }

            &__empty {
                background-image: url('../../static/empty-img.svg');
                
            }
        }
    }

    &__button {
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;

        &__preloader {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
}
.banner_picture {
    position: relative;
    width: 100%;
    min-height: 70px;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
}

.banner_picture img {
    width: 100%;
    height: 100%;
}

.button_banner {
    margin-top: 20px;
    padding: 14px 25px;
    width: 100%;
    height: 48px;
    background: #FD7D89;
    border-radius: 10px;
    color: #FFFFFF;
    font-size: 14px;
    margin-bottom: 0;
}

.title_banner {
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 17px;
    line-height: 19px;
    /* identical to box height */
    text-align: center;
    /* Black */
    color: #000000;
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}
</style>
