<template>
    <f7-sheet
        class="survey-preview-sheet"
        style="height:auto; --f7-sheet-bg-color: #fff;"
        backdrop
        swipe-to-close
        close-by-backdrop-click
        close-by-outside-click
        @sheet:closed="closeModal()"
    >
        <div>
            <div class="survey-preview-sheet__header">
                <div class="survey-preview-sheet__swipe-line-banner"></div>
                <!-- <div class="survey-preview-sheet__title">Новый опрос</div> -->
            </div>
            <div class="survey-preview-sheet__image" @click="onClick()">
                <img :src="`static/survey/banner-3.png`" class="lazy lazy-fade-in" />
            </div>
            <button 
                class="survey-preview-sheet__button-submit"
                @click="onClick()"
            >Перейти к опросу</button>
        </div>
    </f7-sheet>
</template>

<script>
    export default {
        name: 'SurveyPreview',
        data: () => ({
            shown: false,
            estimation: 10,
            comment: '',
            survey: {},
            activeQuestion: {
                type: ''
            },
            activeQuestionIndex: 0,
            finished: false
        }),
        computed: {
            imageSrc() {
                return this.survey.picture ? `${process.env.VUE_APP_IMAGES_URL}/${ this.survey.picture }` : '';
            },
            buttonStyle() {
                const style = [];
                if(this.survey.buttonColor) {
                    style.push(`color: ${this.survey.buttonColor};`);
                }
                if(this.survey.buttonBackground) {
                    style.push(`background: ${this.survey.buttonBackground};`);
                }
                return style.join(' ');
            }
        },
        mounted() {
            this.$root.$on('surveyShowPreview', async survey => {
                this.survey = {
                    ...this.survey,
                    ...survey
                }
                this.$f7.sheet.open('.survey-preview-sheet');
            });
        },
        methods: {
            onClick() {
                this.$f7.sheet.close('.survey-preview-sheet');
                this.$root.$emit('surveyShowPopup', this.survey);
            },
            closeModal() {
                window.dispatchEvent(new Event('resize'));
            }
        },
    };
</script>

<style lang="scss">
.survey-preview-sheet {
    height:auto;

    &__header {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    &__swipe-line-banner {
        width: 40px;
        height: 4px;
        background: #EAEAEA;
        border-radius: 40px;
        margin-bottom: 10px;
    }

    &__image {
        margin-bottom: 20px;
        img {
            width: 100%;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 130%;
        color: #000000;
        margin-bottom: 10px;
    }

    &__button-submit {
        height: 47px;
        background: #FF7E8A;
        border-radius: 10px;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #FFFFFF;
    }
}
</style>
